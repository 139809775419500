<template>
    <div class="key-message-block">
        <div class="index">{{ index }}</div>
        <div class="content">
            <div class="header">
                <div class="label">{{ data.name }}</div>
                <div class="button-group">
                    <div class="manager-delete-button" @click="onDelete">
                        刪除
                    </div>
                    <div class="manager-edit-button" @click="onEdit">編輯</div>
                </div>
            </div>
            <div class="image">
                <img :src="data.imgUrl" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';

export default {
    props: {
        data: Object,
        index: Number,
    },
    methods: {
        ...mapMutations({
            updateConfirmationState: 'popup/updateConfirmationState',
            updateConfirmationText: 'popup/updateConfirmationText',
            updateConfirmationFunction: 'popup/updateConfirmationFunction',

            setTarget: 'keyMessage/setTarget',
        }),
        ...mapActions({
            apiClient: 'api/invoke',
        }),

        onEdit() {
            this.setTarget(this.data);
            this.$emit('toogle');
        },
        onDelete() {
            this.updateConfirmationText({
                cancel: '取消',
                confirm: '確定',
                title: '確定要刪除此Key Message嗎？',
            });
            this.updateConfirmationState(true);

            this.updateConfirmationFunction(async () => {
                const PAYLOAD = {
                    category: 'KeyMessage',
                    function: 'delete',
                    successMsg: 'Key Message已刪除',
                    data: this.data.id,
                };
                await this.apiClient(PAYLOAD);
                this.$emit('update');
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.key-message-block {
    width: 49%;
    height: 48%;
    display: flex;
    .index {
        width: 25px;
        height: 25px;
        font-size: 15px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        .header {
            display: flex;
        }
        .label {
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 18px;
        }
    }
    .button-group {
        font-size: 16px;
        > * {
            cursor: pointer;
        }
    }
    .image {
        margin-top: 10px;
        text-align: right;
        height: 100%;
        max-height: 90%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
</style>
