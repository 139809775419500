import { render, staticRenderFns } from "./KeyMessage.vue?vue&type=template&id=323e8dcf&scoped=true&"
import script from "./KeyMessage.vue?vue&type=script&lang=js&"
export * from "./KeyMessage.vue?vue&type=script&lang=js&"
import style0 from "./KeyMessage.vue?vue&type=style&index=0&id=323e8dcf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323e8dcf",
  null
  
)

export default component.exports