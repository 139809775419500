import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            pluginConfig: (state) => state.config.plugins,
        }),
    },
    created() {
        const CURR_ROUTE_NAME = this.$route.name;

        const isCurrentManager = this.$route.path.match(/manager/);
        const isCurrentSales = this.$route.path.match(/sales/);
        const isCurrentDoctor = this.$route.path.match(/doctor/);

        const isManagerPluginAllowed = this.pluginConfig.manager.includes(CURR_ROUTE_NAME);
        const isSalesAllowed = this.pluginConfig.sales.includes(CURR_ROUTE_NAME);
        const isDoctorAllowed = this.pluginConfig.doctor.includes(CURR_ROUTE_NAME);

        if (isCurrentManager && !isManagerPluginAllowed) this.$router.push({ name: 'Manager_Case_List' });
        else if (isCurrentSales && !isSalesAllowed) this.$router.push({ name: 'Sales_Case_List' });
        else if (isCurrentDoctor && !isDoctorAllowed) this.$router.push({ name: 'Doctor_Login' });
    },
};
