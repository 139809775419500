<template>
    <pop-up background-color="rgba(0, 0, 0, 0.7)" :on-close="onCancel">
        <div class="text">
            <div class="title">圖片管理與上傳</div>
        </div>
        <div class="main">
            <div class="header">
                <div>排序</div>
                <div>名稱</div>
            </div>
            <div class="table">
                <div class="order">{{ sort || currentIndex }}</div>
                <div class="name">
                    <el-form
                        :model="form"
                        :rules="rules"
                        ref="form"
                        class="case-view"
                    >
                        <el-form-item fluid prop="name">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="upload">
                    <el-upload
                        v-if="!imgUrl"
                        action
                        :http-request="uploadImage"
                        :show-file-list="false"
                        :before-upload="beforeAvatarUpload"
                    >
                        <button-wrapper type="modal" color="primary">
                            上傳照片
                        </button-wrapper>
                    </el-upload>
                    <div v-else class="image-uploaded">
                        <i
                            class="close el-icon-close danger-bg-button"
                            @click="onDeleteImage"
                        >
                        </i>
                        <img :src="imgUrl" alt="" />
                    </div>
                </div>
            </div>
            <div v-if="imgUrl" class="button-group">
                <button-wrapper type="modal" color="cancel" @click="onCancel">
                    取消
                </button-wrapper>
                <button-wrapper type="modal" color="primary" @click="onSubmit">
                    確定上傳
                </button-wrapper>
            </div>
        </div>
    </pop-up>
</template>

<script>
import PopUp from '@/components/Popup/PopUpWrapper.vue';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
    components: { PopUp },
    props: {
        currentIndex: Number,
    },
    computed: {
        ...mapState({
            target: (state) => state.keyMessage.target,
        }),
        isEditMode() {
            return !!this.target;
        },
    },
    data() {
        return {
            form: { name: '' },
            rules: {
                name: [
                    {
                        required: true,
                        message: '此欄位必填',
                        trigger: 'blur',
                    },
                ],
            },
            imgUrl: undefined,
            sort: undefined,
        };
    },
    created() {
        this.fetchDate();
    },
    destroyed() {
        this.setTarget(undefined);
    },
    methods: {
        ...mapMutations({
            setTarget: 'keyMessage/setTarget',
        }),
        ...mapActions({
            apiClient: 'api/invoke',
            createKeyMessage: 'keyMessage/createKeyMessage',
            updateKeyMessage: 'keyMessage/updateKeyMessage',
        }),
        fetchDate() {
            if (!this.isEditMode) return;
            this.form.name = this.target.name;
            this.imgUrl = this.target.imgUrl;
            this.sort = this.target.sort;
        },
        async uploadImage(raw) {
            const data = new FormData();
            data.append('file', raw.file);

            const PAYLOAD = {
                category: 'Util',
                function: 'uploadImage',
                data,
            };
            const RES = await this.apiClient(PAYLOAD);
            this.imgUrl = RES.data.url;
        },
        beforeAvatarUpload(file) {
            const LIMIT_SIZE = 15;
            const isValidateFormat = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < LIMIT_SIZE;

            if (!isValidateFormat) {
                this.$message.error('請選擇jpg或png格式');
            }
            if (!isLt2M) {
                this.$message.error(`檔案大小不可超過 ${LIMIT_SIZE}MB!`);
            }
            return isValidateFormat && isLt2M;
        },
        onDeleteImage() {
            this.imgUrl = undefined;
        },
        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const SUBMIT = {
                        name: this.form.name,
                        imgUrl: this.imgUrl,
                    };
                    if (this.isEditMode) this.save(SUBMIT);
                    else this.create(SUBMIT);
                    return true;
                }
                return false;
            });
        },
        async create(submit) {
            try {
                await this.createKeyMessage(submit);
                this.onCancel();
            } catch (e) {
                console.log(e);
            }
        },
        async save(submit) {
            const DATA = {
                id: this.target.id,
                data: submit,
            };
            try {
                await this.updateKeyMessage(DATA);
                this.onCancel();
            } catch (e) {
                console.log(e);
            }
        },
        onCancel() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    color: #000000;
    font-size: 24px;
    line-height: 35px;
}
.main {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    > :first-child {
        margin: 20px 0;
    }
    .header,
    .table {
        display: flex;
        text-align: center;
        > :first-child {
            width: 50px;
            margin-right: 20px;
        }
    }
    .upload {
        margin-left: 20px;
    }
    .image-uploaded {
        position: relative;
        max-width: 150px;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .close {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            width: fit-content;
            border-radius: 50%;
            padding: 5px;
            transform: translate(50%, -50%);
        }
    }
    .button-group {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        > :first-child {
            margin-right: 20px;
        }
    }
}
</style>
