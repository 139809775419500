<template>
    <div class="key-message list-page">
        <div class="inner">
            <div class="button-group" />
            <div class="display-section">
                <button-wrapper
                    type="modal"
                    color="primary"
                    :disabled="isUpToLimit"
                    @click="toggleAddKeyMessage"
                >
                    新增
                </button-wrapper>
                <div class="key-message-section">
                    <div class="key-message-inner">
                        <KeyMessageBlock
                            v-for="(data, index) in keyMessages"
                            :key="index"
                            :index="index + 1"
                            :data="data"
                            @toogle="toggleAddKeyMessage"
                            @update="getKeyMessageList"
                        />
                    </div>
                </div>
            </div>
            <div class="policy-box">
                <div
                    class="item reveal-information"
                    @click="onOpenRevealInformationPopup"
                >
                    羅氏揭露訊息
                </div>
            </div>
        </div>
        <transition name="fade" ease="in-out">
            <AddKeyMessage
                v-if="popup"
                :currentIndex="keyMessages.length + 1"
                @close="toggleAddKeyMessage"
            />
        </transition>
    </div>
</template>

<script>
import GuardDisabled from '@/middleware/GuardDisabled';
import KeyMessageBlock from '@/components/KeyMessage/Block.vue';
import AddKeyMessage from '@/components/Popup/AddKeyMessage.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

const KEY_MESSAGE_AMOUNT_LIMIT = 2;

export default {
    mixins: [GuardDisabled],
    components: { KeyMessageBlock, AddKeyMessage },
    computed: {
        ...mapState({
            keyMessages: (state) => state.keyMessage.list,
        }),
        isUpToLimit() {
            return this.keyMessages.length >= KEY_MESSAGE_AMOUNT_LIMIT;
        },
    },
    created() {
        this.getKeyMessageList();
    },
    data() {
        return {
            popup: false,
        };
    },
    methods: {
        ...mapMutations({
            updateRevealInformationPopupState: 'popup/updateRevealInformationPopupState',
        }),
        ...mapActions({
            getKeyMessageList: 'keyMessage/getKeyMessageList',
        }),
        toggleAddKeyMessage() {
            this.popup = !this.popup;
        },
        onOpenRevealInformationPopup() {
            this.updateRevealInformationPopupState(true);
        },
    },
};
</script>

<style lang="scss" scoped>
.key-message-section {
    margin-top: 3%;
    height: 88%;
    overflow: auto;
    .key-message-inner {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        > * {
            height: 300px;
            margin-bottom: 30px;
        }
    }
}
</style>
